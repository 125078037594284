
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UmbrellaPolicyList from "@/components/reusable/policy-information/UmbrellaPolicyList.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-umbrella-policy",
  components: { UmbrellaPolicyList },
  data() {
    return {
      route: variables.BROKER_UMBRELLA_POLICY_ROUTE,
      editRouteName: "edit-broker-umbrella-policy"
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Umbrella Policies", ["Broker", "Cooperative"]);
    });
  },
  created() {
    //Set page title
    document.title = "Broker Umbrella Policy | " + process.env.VUE_APP_TITLE;
  }
});
