
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "umbrella-policy-list-component",
  components: {},
  props: {
    route: String,
    editRouteName: String,
    returnRoute: String
  },
  data() {
    return {
      policies: []
    };
  },
  created() {
    // Get the cooperative umbrella policies
    this.getUmbrellaPolicies();
  },
  methods: {
    getUmbrellaPolicies() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.route}`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    }
  }
});
