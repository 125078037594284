import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UmbrellaPolicyList = _resolveComponent("UmbrellaPolicyList")!

  return (_openBlock(), _createBlock(_component_UmbrellaPolicyList, {
    route: _ctx.route,
    "edit-route-name": _ctx.editRouteName,
    "return-route": 'broker-umbrella-policy'
  }, null, 8, ["route", "edit-route-name"]))
}